import Image from "next/image";
import LastSevenDaysProgress from "../LastSevenDaysProgress/LastSevenDaysProgress";
import { useState } from "react";
import * as m from "@/paraglide/messages";
import { languageTag } from "@/paraglide/runtime";

export default function Streak({ session, showTipsOnTop = false }) {
    const locale = languageTag();

    const [openStreakInfos, setOpenStreakInfos] = useState(false);

    return (
        <div
            className="flex items-center gap-1.5 hover:background-blur dark:hover:background-blur-dark duration-100 cursor-pointer p-2 rounded-xl relative"
            onMouseOver={() => setOpenStreakInfos(true)}
            onMouseOut={() => setOpenStreakInfos(false)}
        >
            <Image
                src="/static/icons/streak.svg"
                width={15}
                height={15}
                alt="Série"
            />
            <div className="text-sm text-gray-800 dark:text-lightBlue font-bold font-geistMono text-opacity-70">
                {session.user.streakCount.toLocaleString(locale)}
            </div>
            {openStreakInfos && (
                <div
                    className={`absolute ${
                        showTipsOnTop ? "bottom-9" : "top-0"
                    } right-0 mt-8`}
                >
                    <div className="bg-white dark:bg-bgDark-700 p-5 mt-2 border dark:border-borderDark rounded-lg shadow-xl w-[400px] cursor-auto">
                        <div className="text-lg text-[#06050F] dark:text-white font-sourceSans font-bold">
                            {m.Streak_title()}
                        </div>
                        <div className="text-[#06050F] text-opacity-60 dark:text-lightBlue font-geistSans text-sm mt-1">
                            {session.user.streakCount > 1
                                ? m.Streak_x_days({
                                      days: session.user.streakCount.toLocaleString(
                                          locale,
                                      ),
                                  })
                                : session.user.streakCount == 0
                                ? m.Streak_zero()
                                : m.Streak_one_day()}
                            .
                        </div>
                        <LastSevenDaysProgress
                            activity={session.user.activity}
                        />
                        <div className="justify-between gap-4 flex mt-5 pt-5 text-sm font-bold text-[#05060F] text-opacity-70 dark:text-lightBlue border-t dark:border-borderDark">
                            <div className="flex flex-col gap-1.5 flex-1">
                                <span className="uppercase text-xs dark:text-white">
                                    {m.Streak_longest()}
                                </span>
                                <div className="flex items-center gap-3">
                                    <div>
                                        <Image
                                            src="/static/icons/streak.svg"
                                            width={20}
                                            height={20}
                                            alt="Série"
                                        />
                                    </div>
                                    {session.user.maxStreakCount.toLocaleString(
                                        locale,
                                    )}{" "}
                                    {session.user.maxStreakCount > 1
                                        ? m.Streak_days()
                                        : m.Streak_day()}
                                </div>
                            </div>
                            <div className="flex flex-col gap-1.5 flex-1">
                                <span className="uppercase text-xs dark:text-white">
                                    {m.Streak_shields()}
                                </span>
                                <div className="flex items-center gap-3">
                                    <div>
                                        <Image
                                            src="/static/icons/shield.svg"
                                            width={20}
                                            height={20}
                                            alt="Boucliers"
                                        />
                                    </div>
                                    {session.user.shields.toLocaleString(
                                        locale,
                                    )}{" "}
                                    {session.user.shields > 1
                                        ? m.Streak_shields_plural()
                                        : m.Streak_shields_singular()}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
